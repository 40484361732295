<template>
  <div>
    <bread-cump :cumps="$route.meta.breadcrumps" />
    <v-container fluid>
      <v-row class="ml-3">
        <h4>Month : {{ $route.params.month }}</h4>
        <h4 class="ml-3">Year : {{ $route.params.year }}</h4>
      </v-row>
      <v-row justify="center">
        <v-col lg="12">
          <v-card class="card--shadow">
            <list :heData="heData" />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import List from "@/components/HE/List";
import { mapState } from "vuex";
import BreadCump from "@/components/navigation/BreadCump";

export default {
  components: {
    List,
    BreadCump,
  },
  computed: {
    ...mapState("HE", ["heData"]),
  },

  created() {
    this.$store.dispatch("HE/getAll", {
      month: this.$route.params.month,
      year: this.$route.params.year,
    });
  },
};
</script>
