
<template>
  <!-- <export-excel
    type="csv"
    class="export_btn"
    :name="`HE activity list (${$route.params.month}/${$route.params.year}).xls`"
    :fields="custom_fields"
    :fetch="getData"
    :footer="[`${$route.params.month}/${$route.params.year}`]"
  >
    <v-icon color="white" left>mdi-chevron-right-circle-outline</v-icon>Export
  </export-excel> -->
  <v-btn
          @click="exportData"
            color="teal accent-4"
            depressed
            dark
            class="mb-1 mr-3"
          >
  <v-icon color="white" left>mdi-chevron-right-circle-outline</v-icon> Export
  </v-btn>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    heData: {
      type: Array,
    },
  },
  computed: {
    ...mapState("Admin", ["admin"]),
  },
  data: () => ({
     township_id: this.admin?this.admin.township_id:8,
      month:this.$route.params.month,
      year: this.$route.params.year,
      

    }),

  methods: {
    exportData(){
      let data = {
        township_id: this.admin?this.admin.township_id:8,
        month:this.$route.params.month,
        year:this.$route.params.year,
      }
     
      this.$store.dispatch("HE/exportHE", data);
    },
    getData() {
      // const processedData = this.heData.map(item => ({
      //     ...item,
      //     n_o_attendence_male: Number(item.n_o_attendence_male), // Convert Age to number
      //   }));
       
      if (this.heData.length > 0) return this.heData;
    },
  },
};
</script>

<style scoped>
.export_btn {
  background: #ff4081;
  border-radius: 3px;
  color: white !important;
  letter-spacing: 1px;
  text-transform: uppercase;
  width: 150px;
  padding: 5px 15px;
  margin-bottom: 1px;
  cursor: pointer;
}
</style>
