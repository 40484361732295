<template>
  <div>
    <v-breadcrumbs large class="py-3" :items="cumps">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          class="breadcrumb-item"
          @click="$router.go(item.to)"
          :disabled="item.disabled"
        >
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
  </div>
</template>

<script>
export default {
  props: {
    cumps: {
      type: Array,
      require: true,
    },
  },
  // methods: {
  //   paramsText(paramObj) {
  //     //loop params object value and convert that value to string
  //     //delete back slah "/" from params string value
  //     let paramString = "";
  //     Object.values(paramObj).forEach((value) => {
  //       paramString = paramString + "/" + value;
  //     });
  //     return paramString.substring(1);
  //   },
  // },
  // computed: {
  // breadcrumbs() {
  //   //create and added new array value
  //   //check route's breadcrums array include params value
  //   //if route param is included, added as a route params in url
  //   let breadcrumbs = this.cumps.reduce((newArr, cumpArr) => {
  //     newArr.push({
  //       link: this.$router.go(parseInt(cumpArr.to)),
  //       text: cumpArr.text,
  //       disabled: cumpArr.disabled,
  //     });
  //     return newArr;
  //   }, []);
  //   console.log(breadcrumbs);
  //   return breadcrumbs;
  // },
  // },
};
</script>

<style scoped>
.breadcrumb-item {
  letter-spacing: 1px;
  cursor: pointer !important;
}
</style>
