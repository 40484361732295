<template>
  <div>
    <confirm-box :dialog="dialog" @confirmed="deletePatient" @cancel="cancel" />
    <v-data-table
      :headers="headers"
      :items="heData"
      :loading="loading"
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event"
      :page.sync="page"
      item-key="id"
      hide-default-footer
      hide-default-header
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>HE - Activity</v-toolbar-title>

          <v-divider class="mx-4" inset vertical></v-divider>
          <export-list :heData="heData" />
          <v-spacer></v-spacer>
          <v-btn
            @click="viewCreateForm"
            color="teal accent-4"
            depressed
            dark
            class="mb-1"
          >
            <v-icon left dark> mdi-plus-circle-outline </v-icon>
            New Item
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:header="{ props: { headers } }">
        <thead class="header">
          <tr>
            <th
              v-for="header in headers"
              :key="header.text"
              class="header--text"
            >
              {{ header.text }}
            </th>
          </tr>
        </thead>
      </template>

      <template v-slot:item="{ index, item }">
        <tr>
          <td>{{ index + 1 }}</td>
          <td>{{ item.date }}</td>
          <td>{{ item.type == 1 ? "community" : "contact" }}</td>
          <td>{{ item.month }}</td>
          <td>{{ item.year }}</td>
          <td>{{ item.screening_person == 1 ? "Vol" : item.screening_person == 2  ? "FO" : ''}}</td>
          <td>{{ item.volunteer &&  item.screening_person == 1 ? item.volunteer.name : "" }}</td>
          <td>{{ item.refer_male==null?'' : item.refer_male }}</td>
          <td>{{ item.refer_female==null?'' : item.refer_female }}</td>
          <td>{{ item.n_o_attendence_male }}</td>
          <td>{{ item.n_o_attendence_female }}</td>
          <td>{{ item.n_o_household_male }}</td>
          <td>{{ item.n_o_household_female }}</td>
          <td>{{ item.n_o_closecontact_male }}</td>
          <td>{{ item.n_o_closecontact_female }}</td>
          <td>{{ item.index_case_no }}</td>
          <td>{{ item.index_case_year }}</td>
          <td>{{ item.index_case_initial }}</td>
          <td>{{ item.township.township_group ? item.township.township_group.name: ''}}</td>
          <td>{{ item.township ? item.township.name : '' }}</td>
          <td>
            <span
              :class="{
                'red accent-3': item.comments.length === 0,
                'green accent-3': item.comments.length != 0,
              }"
              class="px-3 py-1 white--text font-weight-bold"
            >
              {{ item.comments.length }}
            </span>
          </td>
          <td>
            {{ item.checked === 1 ? "Yes" : "No" }}
          </td>
          <td>
            <div class="mr-2">
              <v-btn
                @click="goUpdateForm(item)"
                icon
                color="orange darken-1"
                class="mr-2 white--text"
                depressed
              >
                <v-icon> mdi-pencil-outline </v-icon>
              </v-btn>
              <v-btn
                @click="openConfrimBox(item)"
                icon
                color="red lighten-1"
                class="white--text"
                depressed
              >
                <v-icon> mdi-delete-outline </v-icon>
              </v-btn>
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
    <div class="text-center py-3">
      <v-pagination
        color="purple accent-4"
        v-model="page"
        :length="pageCount"
      ></v-pagination>
    </div>
  </div>

</template>

<script>
import { mapState } from "vuex";
import ConfirmBox from "@/components/notification/ConfirmBox";
import ExportList from "./exportList.vue";

export default {
  props: {
    heData: {
      type: Array,
    },
  },
  components: {
    ConfirmBox,
    ExportList,
  },
  data: () => ({
    pageCount: 0,
    page: 1,
    itemsPerPage: 10,
    item: {},
    dialog: false,
    headers: [
      { text: "No", value: "no" },
      { text: "Date", value: "date" },
      { text: "Type", value: "type" },
      { text: "Month", value: "month" },
      { text: "Year", value: "year" },
      { text: "Screening Person", value: "screening_person" },
      { text: "Volunteer", value: "volunteer" },
      { text: "Refer Male", value: "refer_male" },
      { text: "Refer Female", value: "refer_female" },
      { text: "Attendence Male", value: "n_o_attendence_male" },
      { text: "Attendence Female", value: "n_o_attendence_female" },
      { text: "Household Male", value: "n_o_household_male" },
      { text: "Household Female", value: "n_o_household_female" },
      { text: "Close Contact Male", value: "n_o_closecontact_male" },
      { text: "Close Contact Female", value: "n_o_closecontact_female" },
      { text: "Case No", value: "index_case_no" },
      { text: "Case Year", value: "index_case_year" },
      { text: "Case Initial", value: "index_case_initial" },
      { text: "Township Group", value: "township_group" },
      { text: "Township", value: "township" },
      { text: "Feedbacks", value: "comments" },
       { text: "Checked", value: "checked" },
      { text: "Actions", value: "actions" },
    ],
  }),
  computed: {
    ...mapState("Loading", ["loading"]),
  },
  methods: {
    viewCreateForm() {
      this.$router.push({
        name: "create-he",
        params: {
          month: this.$route.params.month,
          year: this.$route.params.year,
        },
      });
    },
    openConfrimBox(item) {
      this.item = item;
      this.dialog = !this.dialog;
    },
    deletePatient() {
      let data = {
        id: this.item.id,
      };
      this.$store
        .dispatch("HE/delete", data)
        .then(() => (this.dialog = !this.dialog));
    },
    cancel() {
      this.dialog = !this.dialog;
    },
    goUpdateForm(item) {
      let convertNumber = parseInt(item.id);
      this.$router.push({
        name: "edit-he",
        params: {
          id: convertNumber,
          month: this.$route.params.month,
          year: this.$route.params.year,
        },
      });
    },
  },
};
</script>

<style scoped></style>
