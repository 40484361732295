<template>
  <v-dialog v-model="dialog" persistent max-width="310">
    <v-card class="py-4">
      <v-card-title class="subtitle-2  text-uppercase">
        <v-icon left>mdi-alert-octagon-outline</v-icon>
        Are you sure want to delete ?
      </v-card-title>

      <v-card-actions class="d-flex justify-center">
        <v-btn outlined color="teal accent-4" dark @click="confirmed">
          <v-icon left>mdi-check-outline</v-icon>
          Yes
        </v-btn>

        <v-btn outlined dark color="red accent-2" @click="cancel">
          <v-icon left>mdi-close-outline</v-icon>
          No
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
    },
  },
  methods: {
    confirmed() {
      this.$emit("confirmed");
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>
